.cinfo{

}

.contact__infoGrid>span{
    text-align: left;
    font-family: var(--fontFamilyA);
}
.contact__infoGrid>.MuiSvgIcon{
    background-color: rgb(212, 0, 255);
    font-size: small;
}

.contact__infoGrid{
    display:grid;
    grid-template-columns: .5fr 2.5fr;
    justify-content: center;
    grid-row-gap: .7rem;
    margin-top: 1.5rem;
    grid-column-gap: 1rem;
    color:var(--grayC);
    justify-content: center;
    align-items: center;
    
}
.cinfo_mlogo{
    width: 40px;
}