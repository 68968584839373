.steps{
    padding-top: 3rem;

}
.steps__title{
    text-align: center;
    position: relative;
}

.steps__items{
    display:grid;
    grid-template-columns:1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    list-style: none
}

.stepCard{
    position:relative;
    margin: 50px;
    display:grid;
    grid-template-rows:2fr .5fr .5fr 2.5fr;
    min-width: 200px;   
    place-items: center;
    /* width:200px; */
    
}
.stepCard img{
    height :90px;
    /* width:100px; */
    /* height:100px; */
    margin:0;
}   

.stepCard> h5{
    margin:0px;
    text-transform:uppercase;
    font-size:18px;
    text-align: center;
    padding: 0;
    color:blueviolet;
    font-weight: bolder !important;
    
    
}
.stepCard__p{
    text-align: left;
    /* color: yellow; */

    
}
/* .steps__title::after{
    content: '';
    position: absolute;
    bottom: -6px;  
    left:47%;
    height: .3rem;
    width: 90px;
    background-color: blueviolet;
    
} */

.steps__num{
    /* display:block;   
    place-items:center; */
    /* display:inline */
    margin:1rem

}
/* 
.steps__num::after{
    content:'';
    background:red;
    height:5px;
    width:300px;
    display:block;
    position:absolute;
    left:0;
    top:120px;
    z-index:-1;
}
.l-first::after{
    width:300px;
    background:grey;
    left:150px
}
.l-last::after{
    width:70px;
    background:blue;
    right:78px

}
 */
 @media screen and (max-width:800px){
    .steps__items{
        grid-template-columns: 1fr;
        justify-items: start;
    }
    .stepCard{
        position:relative;
        margin: 0px;
        display:grid;
        grid-template-rows:1fr;
        width: 100%; 
        margin: 0;  
        padding: 0;
    
    }
    .stepCard__p{
        /* padding: 0;
        margin: 0; */
        margin-right: 2rem;
    }
}

@media screen and (max-width:1200px){
    .steps__items{
        grid-template-columns: 1fr 1fr;
        justify-items: start;
    }
    .stepCard{
        position:relative;
        margin: 0px;
        display:grid;
        grid-template-rows:1fr;
        width: 100%; 
        margin: 0;  
        padding: 0;
    
    }
    .stepCard__p{
        /* padding: 0;
        margin: 0; */
        margin-right: 2rem;
    }
}


@media screen and (max-width:800px){
}
.stepCard__p{
    text-align: center !important;
}