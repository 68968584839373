
.products{
    /* display: grid; */
    padding-top: 3rem;
    background:var(--colorA1);
    color:whitesmoke;
    padding-bottom: var(--secPaddingBtm);
    padding-top: var(--secPaddingBtm);
}
.products__title{
 color:whitesmoke;
 text-align: center;
 /* font-size: 25px; */
}
.products__introP{
    text-align: center;
    line-height: 1.7rem;
}
.products__list{
    display:grid;
    /* grid-template-columns:350px 350px; */
    grid-template-columns:1fr 1fr;
/* grid-template-rows:1fr 1fr ; */
    place-items:center;
    row-gap: 2.5rem;
    column-gap: .5rem;
}
.product{
    display:flex;
    Justify-content:center;
    align-items:center;
    max-width:400px
}
.product__info{
    display:flex;
    flex-direction:column;
    margin-left:1rem
}
.product__info h3{
    margin:0;
    position:relative;
    font-size: x-large;
}

.product__info h3::after{
    position:absolute;
    content:'';
    background:white;
    height: 0.1em;;
    width:100%;
    display:block;
    position:absolute;
    left:0;
    bottom:0;
    z-index:200;
}
.product__infoP{
    /* margin:0; */
}

.products p{
    /* text-align: center; */
    /* place-items: center;
    max-width: 300px; */
    /* background-color: yellow;
    color: brown; */
}


@media screen and (max-width:800px){
    .products__list{
        grid-template-columns: 1fr;
        padding-left: var(--sidesPadding);
        padding-right: var(--sidesPadding);
        justify-items: start;
        
    }
    .product{
        place-items: center;
        max-width:100%
    }
    .products__title, .products__introP{
        text-align: left;
        margin-left:2.5rem;
        /* text-align: justify; */
       }
    
}
.product__info>p{
    font-size: medium;
}