.menubar{
    background-color: var(--colorC);
    text-align: center;
    position: fixed;
    z-index: 1900;
    width: 100%;
    top:0px;
    left:0px;
}
.menubar__logo{
    width: 180px;
    margin: 0;
    padding: 0;
}
.menubar>nav{
    background-color: var(--colorC);
    position: absolute;
    text-align: center;
    top:100%;
    left:0;
    width: 100%;
   
    transform-origin:top;
    transform: scale(1,0);
    transition: transform 400ms ease-in-out;
}
.menubar>nav    ul{
    margin:0;
    padding: 0;
    list-style: none;
}
.menubar>nav li{
    margin:1em 0;
    
    
}

.menubar>nav a{
    color:black;
    text-decoration: none;
    font-size: 1.2rem;
    padding-left: 1em;
    text-transform: uppercase;
}
.menubar>nav a:hover{
    color:var(--colorA);
    font-weight: bold;
    
}
.menubar__toggle{
    display: none;
    
}
.menubar__toggle__label{
    position: absolute;
    top:0;
    right:0;
    margin:1.2em;
}


.menubar__toggle:checked ~ nav{
    display: block;
    transform: scale(1,1);

}


@media screen and (max-width:800px){
    .navbar{

        display:none;
        
    }
    .Topbar{
        display:none;
        
    }
    .Topbar__contact{
        display: none;
    }

}
@media screen and (min-width:800px){
    .menubar{
        display:none;
    }
    

}
