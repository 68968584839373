.floaticon{
    position: fixed;
    right: 0;
    top:70%;
    
    transition: transform 400ms ease-in-out;
    clear: both;
}
.floaticon .MuiSvgIcon-root{
    color: green;
    font-size: 3rem;
}


.floaticon :hover{
    /* transform: scale(1.4); */
    transform-origin:left;
    /* transform: scale(1,0); */
    
    transform: rotateY(20deg);
}
.footerbar__icon a{
    text-decoration: none;
    /* background-color: gray;
    padding: .3em;
    border-radius: 50%; */
}