
/* .navbar__nav .MuiSvgIcon-root{
    color: rgba(9, 182, 152, 0.99);
}
.navbar__nav>ul>li>a>span{
    font-weight: bolder;
    color: white;
}
.navbar__nav>ul>li>a:hover span {
    color: grey;
} */


.navbar{
    margin: 0;
    background-color: var(--colorA);
    position: sticky;
    top:0;
    left:0;
    display: block;
    z-index: 100000;
}

.navbar__nav{
    padding:1.2rem;
}

.navbar__nav ul{
    margin:0;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
  
}

.navbar__nav li{
    margin: 0 1rem;
    position: relative;
    
}

.navbar__nav a{
    text-align: none;
    display:flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;   
}

.navbar__nav a> span{
    text-transform: uppercase;  
    padding: 0 .3rem;
    font-weight: 500;
    color: whitesmoke;
}
.navbar__nav a> span:hover{
    text-transform: uppercase;  
    padding: 0 .3rem;
    font-weight: 500;
    color: blueviolet;
    font-weight: bolder;

}

.navbar__nav .MuiSvgIcon-root{
    color: lightcoral;
}







/* .navbar__nav a::before{
    position: absolute;
    content:'';
    display: block;
    height: 5px;  
    background-color: rgba(7, 27, 109, 0.4);
    top:-1em;
    left:0;
    right: 0;
    transform: scale(0,1);
    transition: transform ease-in-oot 2s;
}
.navbar__nav a:hover::before{
    transform: scale(1,1);
} */



.navbar__nav .MuiSvgIcon-root{
    color: blueviolet;
}




