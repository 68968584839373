.formX{
    /* padding: 1rem; */
}
.formX > form{
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.formX__inputsContainer{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    /* grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); */
    grid-column-gap: 1rem;
    grid-row-gap:1rem;
}


.grid-span-1{
    grid-column: 1/-1;

}
.formbtn{
    
}
/* @media (max-width:500px){

    .formX__inputsContainer{
        display: flex;
        flex-direction: column;
    }
    
} */



/* .formX__inputsContainer>span{
    text-align: left;
} */