.banner{
    margin:0;
    /* color :red; */
    position: relative;
    font-family: ProximaNova-Regular,'Helvetica Neue',Helvetica,Arial,sans-serif;

    
}
.banner__content>*{
    margin: 0;
    
}

.banner__contentPhone{
    position: absolute;
    bottom:10px;
    left:1rem;
    text-align: left;   
    display: flex;
    flex-direction: column  ; 
    gap:0em;
    color:black;
    backdrop-filter: blur(2px);
    padding: 2em 1em;
    border-radius: 0 7% 0 7%;
    width: auto;
}   
.banner__contentPhone>*{
    margin:0;
    padding: 0;

}    
.banner__contentPhone>h2{
    margin: 0 !important;
    padding: 0 !important;
}   
.banner__content{
    position: absolute;
    top:25%;
    left:1rem;
    text-align: left;   
    display: flex;
    flex-direction: column  ; 
    gap:1em;
    color:black;
    backdrop-filter: blur(2px);
    padding: 2em 1em;
    border-radius: 0 7% 0 7%;
}   
.banner__content h2{
    padding:0;
    text-transform: capitalize;
    padding-left: 2rem;
    margin:0;
    /* width: %; */
    padding-right: 0px;
    font-weight:  bold;
    font-size: xx-large;
    
    
}

.banner__content p{
    padding: 0rem 2rem;
    font-size:xx-large;
    margin-bottom: 0;
    padding-bottom: 0;
}
.banner__content span{
    padding: 0rem 2rem;
    font-style: italic;
    max-width: 70%;
    font-size: large;
}
.Banner__btns{
    padding: 0rem 2rem;
    display:flex;
    column-gap: 1.5rem;
    /* width: ; */
}
.aimbar{
    
}


.phone_banner_carousel{
    margin-bottom: 0;
    display: none;
    
}
.banner__content2{
    position: absolute;
    bottom:30%;
    left:10rem;
    text-align: left;   
    display: flex;
    flex-direction: column  ; 
    gap:1em;
    color:black;
    /* backdrop-filter: blur(45px); */
    padding: 2em 1em;
    border-radius: 0 7% 0 7%;
}
.banner__productList h2{
    font-size: 900px;
    color: aqua !important;
    margin: 0;
    padding: 0;
}

.banner__productList{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: flex-start;
    
}

.product_b{
    display: flex;
    justify-content: center;
    align-items: center;
    color: black; 

}
.product__info_b{
    margin-left: 2rem;
}

.product_b .MuiSvgIcon-root{
    color: var(--colorA);
}

.product__info_b h3{
    /* font-size: ; */
    /* font-weight: px; */
    font-size: 20;
    font-style: normal;
     
}
.banner__content2 h2{
    color: black;
    font-size: 40px;
    margin: 0;  
    padding: 0;
    font-family: ProximaNova-Regular,'Helvetica Neue',Helvetica,Arial,sans-serif;

}
.banner__cont{
    top:35% !important;
}
.banner__cont>h1,.banner__cont>h3{
    padding: 0;
    margin: 0;
}
.banner__cont h1{
    padding: 0;
    margin: 0;
    font-size: 50px;
    margin-bottom: 0;
    padding-bottom: 0;
}

@media screen and (max-width:800px){
    .banner{
        /* background-image: none !important; */
        /* background-color: var(--colorA); */
        /* height: auto !important; */
        padding: 1.8rem 0;
        margin-top: .1rem;
        height:400px !important
        
    }
    .phone_banner_carousel{
        display: block;
    }
    .desktop_banner_carousel{
        display: none;
    }
    
    .banner__content{
        
        position: static;
        text-align: left;   
        display: grid;
        flex-direction: column; 
        width: auto;
        justify-items: start;
        align-items: center;
        place-content: center;
        
    }
    .Banner__btns{
        display: none;
    }
    .Banner__btnsPhone{
        display: inline;
        margin-left: 1.8rem;
    }

    .Banner__btnsPhone .MuiSvgIcon-root{
        color: white;
        margin: 0;
        padding: 0;
    }

}


.banner__content>*{
    margin-right: 0 !important;
    padding-right: 0 !important;
}

