.footer{
    display: flex;
    background-color: whitesmoke;
    padding-bottom: .5rem;
    border-top: 3px solid rgba(0, 176, 240, .5);
}
.footer_aboutUs{
    flex: 1;
    margin-left: 1rem;
}
.footer_links{
    flex: 1.5;
    display: grid;
    grid-template-columns: 1fr 1fr;
    
}
.footer__logo{
    width: 450px;
    /* transform: skewY(-5xdeg) */
}
.footer__logo:hover{
    transition: transform 900ms ease-in-out;
    transform-origin:left;
    /* transform: scale(1,0); */
    transform: rotateX(360deg);
}

.footer_aboutUs >p, .footer_aboutUs >h2{
    margin-left: 4rem;

}

.footer h4{
    margin: .5rem 0;
    padding: 0;
    font-weight: lighter; 
    /* padding-left: 1r em; */
}
.footer h5{
    padding-top: 2rem;
}
.footer_aboutUs p{
    max-width: 350px;
}
.footer__coyright{
    display: none;
}

@media screen and (max-width:800px){
    .footer{
        display:block
    }

    .steps__items{
        grid-template-columns: 1fr;
        justify-items: start;
    }
    .footer_links{
        display: none;
    }
    .footer__coyright{
        display: block;
        padding: 1rem 0rem;
    }
    .footer_aboutUs p{
        max-width: 90%;
    }
    .footer__logo{
        width:85%;
    }
    .footer_aboutUs >p, .footer_aboutUs >h2, .footer__coyright{
        margin-left: 2rem;
        margin-right: 2rem;
    
    }
}

.footer_linksA>h4>a{
    text-decoration: none;
    color:black
}
.footer_linksA>h4>a:hover{
    text-decoration: none;
    color: blue;
    transform: scale(1.2);
    font-weight: bolder;

}