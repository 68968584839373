.Topbar{
    /* background-color: whitesmoke; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 3rem;
}
.Topbar__logo>img{
    width: 300px;
}
.Topbar__contact{
    display: flex;
}
.Topbar__contacItem{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Topbar__contactLeft{
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin: 0 1.3rem;
}
.Topbar__contactLeft>h4{
    margin: 0;
    font-weight: 500;
}
.Topbar__contactLeft>span{

}
.Topbar__contactRight{
    outline: 2px solid var(--colorB);
    border-radius: 40%;
    padding: .5rem;
}


