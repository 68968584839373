
/* media q for almost ful screen */
.stories{
    /* padding:0 8rem; */
    padding-bottom: var(--secPaddingBtm);
    padding-top: var(--secPaddingBtm);
    background: var(--colorB2);
    color: whitesmoke;
}

.stories__title{
    color:white;
    text-transform: uppercase;
    text-align: center;
   }

.stories__list{
    display:grid;
    grid-template-columns:1fr 1fr ;
    grid-template-rows:1fr 1fr ;
    /* justify-content: center; */
    place-items:center;
    row-gap: 2.5rem;
    column-gap: .5rem;
    /* gap:0rem; */


}
.story{
    margin:0;
    padding:0;
    position:relative;
    
}
.story__autor{
    display:flex;
    justify-content:start;
    align-items:center;
    column-gap: 1rem
}


.story__autor h4{
    margin:0;
}
.story p{
    text-align:left;
    width:450px;
}

.story::after{
 
    content:   '"';
    font-family: "Bradley Hand" ;
    /* font-family: "Times New Roman", Times, serif; */
    font-size: 50px;
    font-weight:bold;
    font-style: italic;
    transform: rotate(-25deg);
    display:block;
    position:absolute;
    left:-40px;
    top:8px;
    z-index:1;
    color:gray;
}
.stories__listPhone{
    display: none;

}

@media screen and (max-width:800px){
    .stories__list{
        /* display: block; */
       
        grid-template-columns: 1fr;
        padding-left: var(--sidesPadding);
        padding-right: var(--sidesPadding);
    }
    .stories__list {
        display: none;
    }
    .story::after{
        display: none;
    }
    .stories__title{
        text-align: left;
        padding-left: var(--sidesPadding);

    }
    .stories__listPhone{
        display: block;
        padding-left: var(--sidesPadding);
        padding-right: var(--sidesPadding);
    }
    .story p{
        text-align:left;
        width:100%;
    }
    .story__autor{
        text-align: left;
    }
    .story__inf{
        padding-left: 1rem;
    }
    
}