
.messageField{
    height: 100px;
    width: 100%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items: start;
  }

.messageField textarea{
    height: 100%;
    width: 100%;
    border: none;
    font-size: 17px;
    border: .4px solid rgba(0,0,0,.4);
    font-family: 'Courier New', Courier, monospace;
    color: black;
    font-weight: bold;
    border-radius: 4%;
    outline: none;
    font-family:inherit
  }


.messageField label{
    padding: .1rem 0;
    color:black;
}


.messageField textarea:focus{
    border: .5px solid rgba(0,0,0,.8); 
    outline: none;
}
.messageField__borderBox{
  padding: .6rem;
  border: .4px solid black;
  border-radius: 5%;
}