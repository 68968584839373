.contact{
    margin-bottom: 2rem;
    font-family: 'Roboto', sans-serif;
    padding-top: 3rem;

}
.contact__container{
    display:grid;
    
    grid-template-columns:1fr 1fr;
    place-items:center;
    align-items: flex-start;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 2rem    ; */
}
.contact__title{
    text-align: center;
}
.contact h1{
    color: rgba(0, 0, 0,.7);
    
    margin-top: 2rem;

}
.contact__boxHeader{
    color: black
}
.contact___formBox{
    display: flex;
    flex-direction: column;
    /* justify-content: start; */
    align-items: flex-start;
    /* margin-right: 2.5rem ; */
}  


@media screen and (max-width:800px){
      .contact__container{
        /* display: block; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        margin: 0;
        padding-left: var(--sidesPadding);
        padding-right: var(--sidesPadding);
      }
    
}