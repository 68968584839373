.about{
    padding-top: 1.5rem;
    display: flex;
    align-items: flex-start;
    /* margin-top: 2rem; */
    /* column-gap :2rem; */
    justify-content: space-around;
    align-items: flex-start;
    /* margin:0 2rem ; */
    /* margin-top: 80px;   */
    /* flex-wrap: wrap;   */
    /* max-width: 600px;                   */
    margin-top: 0 !important;
}
.about__title{

}
.about__left p{
    text-align: left;
    font-size: larger;
    line-height: 1.7rem;
    /* font-family: 'Times New Roman', Times, serif; */
    font-size: 20px;
    font-family: ProximaNova-Regular,'Helvetica Neue',Helvetica,Arial,sans-serif;
}
.about__left{
    flex:.6;
    
}
.about img    {
    flex:.5;
    justify-self: flex-start;
    
    /* border-radius: 20%; */
    width: 400px;
    /* background: radial-gradient(transparent 20%, white 70%); */
}

.about__img{
    /* box-shadow: 0 0 8px 8px gray inset; */
    /* border: 2px solid red; */
}
@media screen and (max-width:850px){
        .about{
            padding-top: 0;
        }
        .about__img{
            display: none;
        }
    
        .about__left{
            flex:1;
            
        }
        .about{
            padding-left: var(--sidesPadding);
            padding-right: var(--sidesPadding);
        }
}
