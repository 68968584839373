.footerbar{
    background-color: whitesmoke;
    border-top: 1px solid rgba(0, 176, 240, .5);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 1rem;
}

.footerbar>h5{
    margin: 0px;
    font-size: 15px;
    /* margin-left: 1.5rem; */
    padding-left: .7rem;
    font-weight: lighter;
    
}
.footerbar__icon>* {
    
}
.footerbar__icon>* :hover{
    transition: transform 900ms ease-in-out;
    transform-origin:left;
    /* transform: scale(1,0); */
    transform: rotateX(360deg);
}