 .textField{
    /* height: 40px; */
    width: 100%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items: start;
    /* margin-bottom: 1.5rem; */
    
  }

.textField input{
    /* height: 100%; */
    /* height: 3rem; */
    width: 100%;
    font-size: 17px;
    border: 0px solid gray;
    padding:.2rem;
    border-radius: 4%;
    font-size: smaller;
  }
  
  
  .textField label{
    padding: .1rem 0;
    color:var(--grayC)
    
  }
  
  .text__fieldBorder:hover  {
    /* border: .5px solid rgba(0,0,0,1);  */
    border: .5px solid rgba(0, 0, 0, .8); 
 
 
    
  }
  
  .text__fieldBorder{
    padding: .6rem;
    border: .4px solid rgba(0,0,0,.4);
    /* border: .4px solid red; */
    border-radius: 5%;
}

input{
  outline: none;
}
